<template id="calendar">
  <div
    class="calendar-wrapper"
    :class="{
      murphyMonth: murphyMonth && !goodDay,
      notMurphyMonth: !murphyMonth && !goodDay,
      detail: detail && !goodDay,
      notDetail: !detail && !goodDay,
      goodDay: detail && goodDay,
      white: colorType.white,
      gray: colorType.gray,
      yellowGray: colorType.yellowGray,
      yellowBlack: colorType.yellowBlack,
      black: colorType.black,
    }"
  >
    <div
      class="banner"
      :style="{
        backgroundImage:
          'url(' + $store.state.images[this.bannerImage + '.png'] + ')',
      }"
      v-if="detail"
    >
      <div class="btn-find" @click="setGoodDay()">找好日</div>
    </div>
    <!-- <div class="block-img" v-if="detail && !murphyMonth">
      <img src="https://api.murphysday.com/uploads/images/liff-N1.png" />
    </div>
    <div class="block-img" v-if="detail && murphyMonth">
      <img src="https://api.murphysday.com/uploads/images/liff-N2.png" />
    </div> -->
    <div class="calendar">
      <div class="headings" v-for="dayLabel in dayLabels">{{ dayLabel }}</div>
      <header class="header">
        <span class="year">{{ currentYear }}年</span>
        <div class="arrow">
          <button v-show="isPreviousMonth" @click="previousMonth">&lt;</button>
        </div>
        <span>{{ currentMonthLabel }}</span>
        <div class="arrow">
          <button v-show="isNextMonth" @click="nextMonth">&gt;</button>
        </div>
        <span class="goToday" @click="setToday">今</span>
      </header>

      <div
        v-for="(day, index) in dates"
        class="day"
        :style="{ '--height': day.murphy.value + '%' }"
        :class="dayClassObj(day)"
      >
        <!-- <div class="day-goodDay" v-if="goodDay"></div> -->
        <div class="circle"></div>
        <button @click="setSelectedDate(day)">
          {{ day.date | formatDateToDay }}
        </button>
      </div>
    </div>
    <template v-if="detail">
      <div class="note">
        本月份
        <span class="bold">好運日</span> 共
        <span class="highlight">{{ countGood }}</span> 天
      </div>
      <div class="note">
        最高
        <span class="bold">好運機率</span>
        <span class="highlight">{{ " " + max.value + "%" }}</span> 共有
        <span class="highlight">{{ max.count }}</span> 天
      </div>
    </template>
    <template v-else>
      <div class="note">
        本月份
        <span class="bold">憂鬱日</span> 共
        <span class="highlight">{{ countBad }}</span> 天
      </div>
    </template>

    <div class="block-img" v-if="!detail">
      <img :src="$store.state.images[colorAD + '.png']" />
    </div>
    <notifyModal
      v-if="detail && murphyMonth && !goodDay"
      :key="3"
      :popID="3"
      :payload="{
        case: 2,
        cardUrl: $store.state.images['POP-3.png'],
      }"
    ></notifyModal>
    <notifyModal
      v-if="detail && !murphyMonth && !goodDay"
      :key="13"
      :popID="13"
      :payload="{
        case: 2,
        cardUrl: $store.state.images['POP-3.png'],
      }"
    ></notifyModal>
    <notifyModal
      v-if="detail && murphyMonth && goodDay"
      :key="4"
      :popID="4"
      :payload="{
        case: 2,
        cardUrl: $store.state.images['POP-4.png'],
      }"
    ></notifyModal>
    <notifyModal
      v-if="detail && !murphyMonth && goodDay"
      :key="14"
      :popID="14"
      :payload="{
        case: 2,
        cardUrl: $store.state.images['POP-4.png'],
      }"
    ></notifyModal>
    <notifyModal
      v-if="monthlyCode == 'o7'"
      :key="16"
      :popID="16"
      :payload="{
        case: 2,
        cardUrl: $store.state.images['POP-6.png'],
      }"
    ></notifyModal>
    <notifyModal
      v-if="monthlyCode == 'o4'"
      :key="7"
      :popID="7"
      :payload="{
        case: 2,
        cardUrl: $store.state.images['POP-7.png'],
      }"
    ></notifyModal>
    <notifyModal
      v-if="monthlyCode == 'o5' || monthlyCode == 'o6'"
      :key="17"
      :popID="17"
      :payload="{
        case: 2,
        cardUrl: $store.state.images['POP-7.png'],
      }"
    ></notifyModal>
    <notifyModal
      v-if="monthlyCode == 'o1'"
      :key="8"
      :popID="8"
      :payload="{
        case: 2,
        cardUrl: $store.state.images['POP-8.png'],
      }"
    ></notifyModal>
    <notifyModal
      v-if="monthlyCode == 'o2' || monthlyCode == 'o3'"
      :key="18"
      :popID="18"
      :payload="{
        case: 2,
        cardUrl: $store.state.images['POP-8.png'],
      }"
    ></notifyModal>
  </div>
</template>

<script>
// import "@/assets/styles/calendar/notDetail.scss";
import "@/assets/styles/calendar/white.scss";
import "@/assets/styles/calendar/gray.scss";
import "@/assets/styles/calendar/yellowGray.scss";
import "@/assets/styles/calendar/black.scss";
import "@/assets/styles/calendar/yellowBlack.scss";

// import "@/assets/styles/calendar/notMurphyMonth.notDetail.scss";
// import "@/assets/styles/calendar/murphyMonth.notDetail.scss";

import "@/assets/styles/calendar/notMurphyMonth.detail.scss";
import "@/assets/styles/calendar/murphyMonth.detail.scss";
import "@/assets/styles/calendar/goodDay.scss";
import notifyModal from "@/components/notifyModal.vue";
import Util from "@/mixins/util";
import _ from "lodash";

const dateFns = require("date-fns");
const dayjs = require("dayjs");
const DAY_LABELS = ["日", "一", "二", "三", "四", "五", "六"];
const MONTH_LABELS = [
  "1月",
  "2月",
  "3月",
  "4月",
  "5月",
  "6月",
  "7月",
  "8月",
  "9月",
  "10月",
  "11月",
  "12月",
];
export default {
  mixins: [Util],
  components: { notifyModal },
  data() {
    return {
      today: null,
      selectedDate: null,
      selectedDay: null,
      currDateCursor: null,
      dayLabels: null,
      murphyDate: [],
      queryMonth: "",
      murphyMonth: false,
      goodDay: false,
      max: {
        value: 0,
        count: 0,
      },
      monthlyCode: "",
    };
  },
  async created() {
    this.dayLabels = DAY_LABELS.slice();
    this.today = new Date();
    this.selectedDate = this.today;
    this.currDateCursor = this.today;
    this.queryMonth = dayjs().format("YYYYMM");
    await this.getGoodDay();
    await this.query();
  },
  props: {
    startDate: {
      required: false,
      type: Date,
    },
    userId: {},
    detail: {},
    birth: {},
  },
  computed: {
    bannerImage() {
      switch (true) {
        case this.murphyMonth && this.goodDay:
          return "NOTE-04";
        case this.murphyMonth && !this.goodDay:
          return "NOTE-02";
        case !this.murphyMonth && this.goodDay:
          return "NOTE-03";
        case !this.murphyMonth && !this.goodDay:
          return "NOTE-01";
      }
    },
    colorType() {
      return {
        white: this.monthlyCode == "o7",
        gray: this.monthlyCode == "o5" || this.monthlyCode == "o6",
        yellowGray: this.monthlyCode == "o2" || this.monthlyCode == "o3",
        yellowBlack: this.monthlyCode == "o1",
        black: this.monthlyCode == "o4",
      };
    },
    colorAD() {
      switch (true) {
        case this.colorType.white:
          return "AD-01";
        case this.colorType.gray:
          return "AD-02";
        case this.colorType.black:
          return "AD-03";
        case this.colorType.yellowGray:
          return "AD-04";
        case this.colorType.yellowBlack:
          return "AD-05";
      }
    },
    countGood() {
      return this.murphyDate.filter((obj) => obj.type == "good").length;
    },
    countBad() {
      return this.murphyDate.filter(
        (obj) =>
          obj.type == "bad" ||
          obj.light == "g4" ||
          obj.light == "g5" ||
          obj.light == "g6"
      ).length;
    },
    isPreviousMonth() {
      let targetDate = dayjs(this.currDateCursor).add(-1, "month");
      let limitDate;
      if (this.detail) {
        limitDate = dayjs().add(-1, "month");
      } else {
        limitDate = dayjs().add(-2, "month");
      }

      let todayDate = this.today.getDate();
      if (
        dayjs(targetDate).format("YYYYMMDD") <
        dayjs(limitDate).format("YYYYMM00")
      ) {
        return false;
      } else {
        return true;
      }
    },
    isNextMonth() {
      let targetDate = dayjs(this.currDateCursor).add(1, "month");
      let limitDate;
      let todayDate = this.today.getDate();
      if (this.detail) {
        // if (
        //   this.currDateCursor.getMonth() == this.today.getMonth() &&
        //   todayDate < 15
        // ) {
        //   return false;
        // }
        limitDate = dayjs().add(2, "month");
      } else {
        // if (todayDate < 15) {
        limitDate = dayjs().add(11, "month");
        // } else {
        // limitDate = dayjs().add(12, "month");
        // }
      }
      if (
        dayjs(targetDate).format("YYYYMMDD") >
        dayjs(limitDate).format("YYYYMM32")
      ) {
        return false;
      } else {
        return true;
      }
    },
    currentMonth() {
      return this.currDateCursor.getMonth();
    },
    currentYear() {
      return this.currDateCursor.getFullYear();
    },
    currentMonthLabel() {
      return MONTH_LABELS[this.currentMonth];
    },
    dates() {
      const cursorDate = this.currDateCursor;
      let startDate = dateFns.startOfMonth(cursorDate),
        endDate = dateFns.lastDayOfMonth(cursorDate);
      const daysNeededForLastMonth = dateFns.getDay(startDate),
        daysNeededForNextMonth = dateFns.getDate(endDate) == 31 ? 5 : 6;

      // 7 - (dateFns.getDay(endDate) + 1) > 6
      //   ? 0
      //   : 7 - dateFns.getDay(endDate) - 1;
      startDate = dateFns.addDays(startDate, -daysNeededForLastMonth);
      endDate = dateFns.addDays(endDate, daysNeededForNextMonth);
      return dateFns.eachDay(startDate, endDate).map((date) => {
        let murphyDay = this.murphyDate.find(
          (obj) => obj.date == dateFns.format(date, "YYYY-MM-DD")
        );

        let isMurphy = murphyDay && murphyDay.type == "bad" ? true : false;
        let value = murphyDay ? murphyDay.value : 0;
        return {
          date,
          isCurrentMonth: dateFns.isSameMonth(cursorDate, date),
          isToday: dateFns.isToday(date),
          isSelected: dateFns.isSameDay(this.selectedDate, date),
          cardUrl: murphyDay ? murphyDay.cardUrl : "",
          // (isMurphy || dateFns.isToday(date)),
          // dateFns.isToday(date),
          murphy: {
            isMurphy,
            value,
            code: murphyDay ? murphyDay.cardValue : "",
            light: murphyDay ? murphyDay.light : "",
          },
        };
      });
    },
  },
  mounted() {
    if (this.startDate) {
      this.currDateCursor = this.startDate;
      this.selectedDate = this.startDate;
    }
  },
  methods: {
    async getGoodDay() {
      let url = `${this.apiURL}/good_day_click/lineID/${this.$store.state.profile.userId}`;
      let res = await fetch(url);
      let data = await res.json();
      this.goodDay = data.clicked;
    },
    async query() {
      let url = this.detail
        ? `${this.apiURL}/detailed_murphy/lineID/${this.$store.state.profile.userId}/calendar/month/${this.queryMonth}`
        : `${this.apiURL}/quick_murphy/birth_date/${this.birth}/calendar/month/${this.queryMonth}`;
      let data = await fetch(url);
      data = await data.json();
      this.monthlyCode = data.monthly_code;
      this.murphyMonth = data.ym_calendar == "u2" ? true : false;
      this.murphyDate = data.data
        // .filter((obj) => {
        //   return obj.level < 0;
        // })
        .map((obj) => {
          return {
            ...obj,
            value: obj.level * 100,
            type: obj.level < 0 ? "bad" : "good",
          };
        });

      // if (data.need_remind && data.need_remind.second_test) {
      //   this.$emit("modalEvent", "recheck");
      // } else if (data.need_remind && data.need_remind.communication) {
      //   this.$emit("modalEvent", "fb");
      // }

      if (this.detail) {
        let counts = {};
        this.murphyDate.forEach((obj) => {
          if (obj.value > 0) {
            obj.value = parseInt(obj.value);
            counts[obj.value] = counts[obj.value] ? counts[obj.value] + 1 : 1;
          }
        });

        // let y = counts.indexOf(Math.max(...counts));
        // let y = counts.reduce((y, current, index) => (current ? index : y), 0);
        // console.log(Object.keys(counts).map((v) => parseInt(v)));
        // console.log(this.max.value);
        this.max.value = Math.max(
          ...Object.keys(counts).map((v) => parseInt(v))
        );
        this.max.count = counts[this.max.value];
        this.max.value = Math.round(this.max.value);
      }

      this.murphyDate = this.murphyDate.map((obj) => {
        obj.value = Math.abs(obj.value);
        return obj;
      });
    },
    async setGoodDay() {
      if (
        !this.goodDay &&
        this.selectedDay &&
        this.selectedDay.murphy.isMurphy
      ) {
        this.selectedDate = null;
      }
      this.goodDay = !this.goodDay;
      let url = `${this.apiURL}/good_day_click/lineID/${this.$store.state.profile.userId}`;
      await fetch(url, {
        method: "post",
        body: JSON.stringify({ clicked: this.goodDay }),
      });
    },
    async setToday() {
      this.queryMonth = dayjs().format("YYYYMM");
      this.selectedDate = this.today;
      await this.query();
      this.currDateCursor = new Date();
    },
    dayClassObj(day) {
      return {
        today: day.isToday,
        current: day.isCurrentMonth,
        selected: day.isSelected,
        hide: !day.isCurrentMonth,
        murphyDay:
          day.murphy.isMurphy ||
          day.murphy.light == "g4" ||
          day.murphy.light == "g5" ||
          day.murphy.light == "g6",
        moneyDay:
          day.murphy.light == "g1" ||
          day.murphy.light == "g2" ||
          day.murphy.light == "g3",
        selectedNotToday: day.isToday && this.selectedDate && !day.isSelected,
      };
    },
    async nextMonth() {
      let targetDate = dayjs(this.currDateCursor).add(1, "month");
      let limitDate;
      let todayDate = this.today.getDate();
      if (this.detail) {
        // if (
        //   this.currDateCursor.getMonth() == this.today.getMonth() &&
        //   todayDate < 15
        // ) {
        //   this.$emit("modalEvent", "comeNext");
        //   return false;
        // }
        limitDate = dayjs().add(2, "month");
      } else {
        // if (todayDate < 15) {
        limitDate = dayjs().add(11, "month");
        // } else {
        // limitDate = dayjs().add(12, "month");
        // }
      }
      if (
        dayjs(targetDate).format("YYYYMMDD") >
        dayjs(limitDate).format("YYYYMM32")
      ) {
        this.$emit("modalEvent", "comeNext");
        return false;
      } else {
        this.queryMonth = dayjs(targetDate).format("YYYYMM");
        await this.query();
        this.currDateCursor = targetDate.toDate();
      }
    },
    async previousMonth() {
      let targetDate = dayjs(this.currDateCursor).add(-1, "month");
      let limitDate;
      if (this.detail) {
        limitDate = dayjs().add(-1, "month");
      } else {
        limitDate = dayjs().add(-2, "month");
      }

      let todayDate = this.today.getDate();
      if (
        dayjs(targetDate).format("YYYYMMDD") <
        dayjs(limitDate).format("YYYYMM00")
      ) {
        this.$emit("modalEvent", "passDay");
        return false;
      } else {
        this.queryMonth = dayjs(targetDate).format("YYYYMM");
        await this.query();
        this.currDateCursor = targetDate.toDate();
      }
    },
    setSelectedDate(day) {
      if (!day.isCurrentMonth) return false;
      if (this.goodDay && day.murphy.isMurphy) return false;
      // if (day.murphy.isMurphy) {
      this.selectedDate = day.date;
      this.selectedDay = day;
      this.$emit("input", this.selectedDate);
      // console.log(day);
      this.$emit("selectMurphy", {
        detail: this.detail,
        value: day.murphy.value,
        day,
        murphyMonth: this.murphyMonth,
      });
      // }
    },
  },
  filters: {
    formatDateToDay(val) {
      return dateFns.format(val, "D");
    },
  },
};
</script>
<style scoped lang="scss">
.calendar-wrapper {
  width: 100vw;
}
.banner {
  height: 110px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .btn-find {
    position: absolute;
    bottom: 10px;
    right: 20px;
    font-size: 24px;
    padding: 4px 16px;
    border-radius: 25px;
    color: #fff;
    background: linear-gradient(129deg, #e90078 0%, #fbae17 50%, #fbae17 100%);
    box-shadow: 3px 3px 5px #000000a8;
  }
}
.calendar {
  border-radius: 3px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  row-gap: 3px;
  // width: calc(100% - 20px);
  width: calc(100%);
  padding: 10px;
  padding-bottom: 0;

  > .top {
    grid-column: 1 / span 7;
  }
  > .headings {
  }
  > .header {
    position: relative;
    padding: 0.75rem;
    padding-top: 4px;
    padding-bottom: 0.25rem;
    font-size: 1.25rem;
    grid-column: 1 / span 7;
    font-family: "Oswald", "Noto Sans TC", sans-serif !important;
    .year {
      font-size: 28px;
      position: absolute;
      left: 8px;
    }
    > span {
      text-align: center;
      font-size: 28px;
      font-weight: 300;
    }

    .arrow {
      width: 58.45px;
    }
    button {
      font-size: 28px;
      border: none;
      background: var(--white);
      margin: 0 1rem;
      padding: 0.25rem 0.5rem;

      &:hover {
        background: var(--black-400);
        transition: background 150ms;
      }
    }

    .goToday {
      font-size: 25px;
      font-weight: 400;
      position: absolute;
      top: 10px;
      right: 8px;
      border-radius: 100%;
      display: inline-block;
      width: 32px;
      height: 32px;
      line-height: 32px;
      padding-bottom: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  > * {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  > .day {
    position: relative;
    color: var(--black-200);
    border-radius: 2px;
    border: 1px solid #fff;
    font-family: "Rubik", sans-serif !important;
    font-weight: 400;
    margin: 3px;
    height: calc(100% - 10px);

    &.hide {
      opacity: 0;
    }
    .circle {
      display: none;
      position: absolute;
      border: none;
      height: calc(78%);
      width: 78%;
      border-radius: 100%;
      z-index: 9999;
    }
    &.selected .circle {
      display: block;
    }
    &.today {
      height: calc(100% - 10px);
      .circle {
        display: block;
      }
    }

    &.selected {
    }

    &.current {
      color: var(--black-100);
    }

    &::before {
      content: "";
      display: inline-block;
      height: 0;
      padding-bottom: 100%;
      width: 0px;
    }
    &.murphyDay {
      --height: 0%;
      position: relative;
      &:after {
        height: var(--height);
        content: " ";
        position: absolute;
        width: calc(100% + 2px);
        bottom: -1px;
        z-index: 999;
        border: 1px solid;
        // border-bottom-left-radius: 2px;
        // border-bottom-right-radius: 2px;
      }
    }

    button {
      z-index: 9999;
      font-family: "Rubik", sans-serif !important;
      font-weight: 400;
      color: inherit;
      font-size: 18px;
      line-height: 18px;
      background: transparent;
      border: none;
      margin: 0;
      padding: 0;
      width: 100%;
    }
  }

  > .today {
    background: var(--black-400);
    border-radius: 2px;
  }
}
.note {
  font-family: "Noto Sans TC", sans-serif !important;
  font-weight: 300;
  text-align: right;
  font-size: 19px;
  margin-right: 15px;
  margin-bottom: -8px;
  position: relative;
  top: -30px;
  .bold {
    font-weight: 500;
  }
  .highlight {
    font-family: "Rubik", sans-serif !important;
    color: #f15a24;
    font-size: 28px;
    font-weight: 300;
  }
}
.block-img {
  // margin-top: 5px;
  background-color: #000;
  img {
    width: 100%;
  }
}

button {
  &:focus {
    outline: none;
  }
}

.text-center {
  text-align: center;
}
</style>
